function validateRequired(value) {
  if (!value || value.length === 0) return "Este campo é obrigatório.";
}

function validateNumber(
  value,
  min = Number.MIN_SAFE_INTEGER,
  max = Number.MAX_SAFE_INTEGER
) {
  value = Number(value);
  if (isNaN(value)) return "Este campo tem de ser um número.";
  if (value > max) return "Este campo tem de ser um número menor do que " + max;
  if (value < min) return "Este campo tem de ser um número maior do que " + min;
}

function validatePhoneNumber(value) {
  value = parseInt(value);
  if (isNaN(value)) return "Este campo tem de ser um número.";
  if (value.length > 9) return "Insira um número de telemóvel válido";
  if (value.length < 9) return "Insira um número de telemóvel válido";
}

function validateInteger(
  value,
  min = Number.MIN_SAFE_INTEGER,
  max = Number.MAX_SAFE_INTEGER
) {
  value = parseInt(value);
  if (isNaN(value)) return "Este campo tem de ser um inteiro.";
  if (value > max)
    return "Este campo tem de ser um inteiro menor do que " + max;
  if (value < min)
    return "Este campo tem de ser um inteiro maior do que " + min;
}

function validateDateCompare(value, date = new Date(), comparison = "lt") {
  if (value.length === 0) return "Este campo é obrigatório.";

  let dateLeft = new Date(value);
  let dateRight = new Date(date);
  let dateRightISO = dateRight.toISOString().split("T")[0];
  switch (comparison) {
    case "eq":
      if (dateLeft !== dateRight)
        return `Este campo tem de ser igual a ${dateRightISO}`;
      else break;
    case "ne":
      if (dateLeft === dateRight)
        return `Este campo não pode ser igual a ${dateRightISO}`;
      else break;
    case "gt":
      if (dateLeft <= dateRight)
        return `Este campo tem de ser depois de ${dateRightISO}`;
      else break;
    case "ge":
      if (dateLeft < dateRight)
        return `Este campo não pode ser antes de ${dateRightISO}`;
      else break;
    case "lt":
      if (dateLeft >= dateRight)
        return `Este campo tem de ser antes de ${dateRightISO}`;
      else break;
    case "le":
      if (dateLeft > dateRight)
        return `Este campo não pode ser depois de ${dateRightISO}`;
      else break;
    default:
      throw new Error(`CompareDate: Invalid comparison - ${comparison}`);
  }
}

function validateLength(value, min = 0, max = Number.MAX_SAFE_INTEGER) {
  if (value.length < min)
    return `Este campo tem de ter pelo menos ${min} carateres.`;
  if (value.length > max)
    return `Este campo não pode ter mais do que ${max} carateres.`;
}

function validateEmail(value) {
  const regex =
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
  if (!regex.test(value)) return "Introduza um email válido.";
}

function validateLink(value, required = true) {
  if (!value && !required) return null;
  const regex =
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)/gi;
  if (!regex.test(value)) return "Introduza um link válido.";
}

function validateEquals(value, other, name) {
  if (value !== other) return `Este campo tem de ser igual ao campo "${name}."`;
}

function validateNone() {
  return null;
}

function validateChecked(value) {
  if (value) return null;
  return "Precisa de confirmar este campo";
}

function validateNIF(nif) {
  if (nif) {
    // https://gist.github.com/Pipas/69ab5b514a53a494ec2a80669e950569
    if (
      !["1", "2", "3", "5", "6", "8"].includes(nif.substr(0, 1)) &&
      !["45", "70", "71", "72", "77", "79", "90", "91", "98", "99"].includes(
        nif.substr(0, 2)
      )
    )
      return "Introduza um NIF válido";
    if (nif.length !== 9) return "Introduza um NIF válido";

    let total =
      nif[0] * 9 +
      nif[1] * 8 +
      nif[2] * 7 +
      nif[3] * 6 +
      nif[4] * 5 +
      nif[5] * 4 +
      nif[6] * 3 +
      nif[7] * 2;

    let modulo11 = total - parseInt(total / 11) * 11;
    // eslint-disable-next-line eqeqeq
    let comparador = modulo11 == 1 || modulo11 == 0 ? 0 : 11 - modulo11;

    // eslint-disable-next-line eqeqeq
    if (nif[8] == comparador) return null;
    return "Introduza um NIF válido";
  } else {
    return null;
  }
}

export {
  validateChecked,
  validateDateCompare,
  validateEmail,
  validateEquals,
  validateInteger,
  validateLength,
  validateLink,
  validateNone,
  validateNumber,
  validateRequired,
  validateNIF,
  validatePhoneNumber,
};
