import PropTypes from "prop-types";
import React from 'react';

const AccessibleDivButton = (props) => {
    const {onClick, style, children, ...otherProps} = props;

    const divStyle = Object.assign({
        cursor: "pointer"
    }, style);

    return (
        <div onClick={onClick} onKeyPress={onClick} style={divStyle} tabIndex="0" role="button" {...otherProps}>
            {children}
        </div>
    )
}

AccessibleDivButton.propTypes = {
    onClick: PropTypes.func.isRequired
}

export default AccessibleDivButton;