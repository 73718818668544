import React from "react";

import { ReactComponent as CoursesSVG} from '../../../assets/icons/admin/courses.svg';
import { ReactComponent as EventsSVG} from '../../../assets/icons/admin/events.svg';
import { ReactComponent as DatabaseSVG } from '../../../assets/icons/admin/management.svg';
import { ReactComponent as ShopSVG} from '../../../assets/icons/admin/shop.svg';
import { ReactComponent as SponsorsSVG } from '../../../assets/icons/admin/sponsors.svg';
import { ReactComponent as StatsSVG } from '../../../assets/icons/admin/statistics.svg';
import { ReactComponent as TrainingsSVG} from '../../../assets/icons/admin/trainings.svg';
import { ReactComponent as ResourcesSVG} from '../../../assets/icons/resource/guidelines.svg'

export function EventsIcon(props) {
    return <EventsSVG {...props}/>
}

export function CoursesIcon(props) {
    return <CoursesSVG {...props}/>
}

export function ResourcesIcon(props) {
    return <ResourcesSVG {...props}/>
}

export function ShopIcon(props) {
    return <ShopSVG {...props}/>
}
export function SponsorsIcon(props) {
    return <SponsorsSVG {...props}/>
}

export function DatabaseIcon(props) {
    return <DatabaseSVG {...props}/>
}

export function StatisticsIcon(props) {
    return <StatsSVG {...props}/>
}

export function TrainingsIcon(props) {
    return <TrainingsSVG {...props}/>
}
