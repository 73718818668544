class FormKey {
    constructor() {
        this.inputs = [];
        this.submits = []
    }

    addInput = (controller) => this.inputs.push(controller)
    removeInput = (controller) => this.inputs = this.inputs.filter(i => i.id !== controller.id);

    addSubmit(onSubmitStart, onSubmitFinish) {
        let id = this.submits.reduce((acc, curr) => Math.max(acc, curr.id), 0) + 1;
        this.submits.push({ id, onSubmitStart, onSubmitFinish });
        return id;
    }
    removeSubmit = (id) => this.submits = this.submits.filter(s => s.id !== id);
    notifySubmitStart = () => this.submits.forEach(submit => submit.onSubmitStart())
    notifySubmitFinish = (error) => this.submits.forEach(submit => submit.onSubmitFinish(error));

    validate() {
        let valid = true;
        this.inputs.forEach((component) => {
            let isValid = component.validate();
            valid = valid && isValid;
        })
        return valid;
    }

    setValues(data) {
        this.inputs.forEach(component => {
            let value = data[component.getName()];
            if (value !== undefined)
                component.setValue(data[component.getName()])
        })
    }

    getValues() {
        let values = {};
        this.inputs.forEach((component) => {
            values[component.getName()] = component.getValue();
        })
        return values;
    }

    setValue = (name, value) => this.getComponent(name).setValue(value);

    clear = () => this.inputs.forEach(component => component.clear());
    getValue = (name) => this.getComponent(name).getValue();
    getComponent = (name) => this.inputs.find(input => input.getName() === name);

    setError = (name, error) => this.getComponent(name).setError(error);
    setSuccess = (name, error) => this.getComponent(name).setSuccess(error);
}

export default FormKey;