import { Button } from "@mui/material";
import classNames from "classnames";
import React from "react";
import "./ButtonBase.css";

import { useNavigate } from "react-router-dom";

const ButtonBase = ({
  className,
  style = {},
  submit = false,
  href,
  externalLink,
  to,
  onClick = () => console.log("Button clicked"),
  fill = false,
  ...otherProps
}) => {
  const styles = Object.assign(
    {
      fontSize: "1em",
      width: fill ? "100%" : "auto",
      height: fill ? "100%" : "auto",

      fontFamily: "inherit",
      fontWeight: 500,
      textTransform: "inherit",

      display: "flex",
      alignItems: "center",
      opacity: "1",
      transition: "all 0.1s ease-in-out",
      boxSizing: "border-box",

      minWidth: "auto",
    },
    style
  );

  const navigate = useNavigate();

  const handleClick = (e) => {
    if (to) {
      e.preventDefault();
      navigate(to);
    } else if (onClick) {
      onClick(e);
    }
  };

  return (
    <Button
      type={submit ? "submit" : "button"}
      href={href}
      target={externalLink ? "_blank" : null}
      rel={externalLink ? "noopener noreferrer" : null}
      onClick={handleClick}
      className={classNames("base-button", className)}
      style={styles}
      {...otherProps}
    />
  );
};

export default ButtonBase;
