import './FormSubmit.css'
import classNames from 'classnames';
import React, {useContext, useEffect, useState} from "react";
import ButtonLight from "../buttons/ButtonLight.js";
import ButtonPrimary from "../buttons/ButtonPrimary.js";
import CircularProgress from "../loading/CircularProgress.js";
import {FormKeyContext} from "./Form.js";



const FormSubmit = ({ className, successMessage, light, disabled, children, noMargin, ...otherProps }) => {
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false)
    const [errorMessage, setErrorMessage] = useState("");
    const formKey = useContext(FormKeyContext);

    useEffect(() => {
        const onSubmitStart = () => {
            setLoading(true);
            setErrorMessage("");
            setSuccess(false);
        }

        const onSubmitFinish = (errorMessage) => {
            setLoading(false);
            setErrorMessage(errorMessage);
            setSuccess(!errorMessage);
        }

        const id = formKey.addSubmit(onSubmitStart, onSubmitFinish);
        return () => { formKey.removeSubmit(id); }
    }, [formKey, successMessage])


    let messages = <></>
    if (errorMessage)
        messages = <p className="form-submit-error">{errorMessage}</p>;
    else if (success && successMessage)
        messages = <p className="form-submit-success">{successMessage}</p>

    const ButtonComponent = light ? ButtonLight : ButtonPrimary;
    const loadingColor = light ? "var(--primary)" : "white";

    return (
        <div className={classNames(className, "form-submit-container", { "with-margin": !noMargin })}>
            <ButtonComponent submit className="form-submit-btn" disabled={loading || otherProps.forceLoading|| disabled} {...otherProps}>
                { loading || otherProps.forceLoading ? <CircularProgress color={loadingColor} size="small">{children}</CircularProgress> : children }
            </ButtonComponent>
            { messages }
        </div>
    );
}

export default FormSubmit;
