export function getProductPrice(product, amount, details) {
    let price = 0
    if(product.discount !==0)
    {
       price =  product.price - (product.price * (product.discount/100))
    }
    else
    {
        price = product.price
    }

    if(details.Logótipo !== "" && details.Logótipo !== undefined && details.Logótipo !== "Sem Logótipo")
    {
        price = price + 5
    }
    if(details["Nome Bordado"] !== ""  && details["Nome Bordado"] !== undefined)
    {
        price = price + 3
    }

    price = price * amount

    return price
}