import axios from "axios";

function startDownload(url, fileName = "FILE", onProgress = (total) => { console.log("Downloaded " + total * 100 + " percent!")}) {
    return new Promise(resolve => {
        axios.get(url, {
            headers: {
                accept: "application/octet-stream",
            },
            responseType: 'blob',
            onDownloadProgress: (event) => {
                let contentLength = event.lengthComputable ? event.total : parseInt(event.target.getResponseHeader('content-length'));

                onProgress(event.loaded / contentLength)
            }
        }).then(res => {
            const downloadUrl = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', fileName)
            document.body.appendChild(link);
            link.click();
            link.remove();
            resolve();
        }).catch(resolve);
    })
}

function startBrowserDownload(url, filename) {
    if (process.env.NODE_ENV === "development") {
        url = "http://localhost:3001" + url;
    }
    const link = document.createElement('a');
    link.href = url;
    link.download = filename || "";
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    link.remove();
    return new Promise(resolve => resolve());
}

function downloadString(string, filename, type = 'text/plain') {
    const element = document.createElement("a");
    const file = new Blob([string], {type: type});
    element.href = URL.createObjectURL(file);
    element.download = filename;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
}

function downloadCSV(csvArray, fileName) {
    let csvStr = csvArray.map(item => item.join(';')).join('\n');
    downloadString("\ufeff" + csvStr, fileName, 'text/csv;charset=utf-8;');
}


export { downloadCSV,downloadString, startBrowserDownload, startDownload }