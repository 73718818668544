import PropTypes from "prop-types";

const ResourceTypeToName = (props) => {
    switch (props.resourceType) {
        case "calculators": return "Calculadoras";
        case "guidelines": return "Guidelines";
        case "infos": return "Infografias";
        case "trees": return "Árvores de Decisão";
        case "tables": return "Tabelas de Conversão";
        case "leaflets": return "Folhetos";
        case "exercises": return "Exercises";
        case "bodyparts": return "Exercises(BP)"
        default: return "Oops, isto não devia estar aqui...";
    }
}

ResourceTypeToName.propTypes = {
    resourceType: PropTypes.string.isRequired,
}

export default ResourceTypeToName;