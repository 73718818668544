import axios from "axios";

class AuthError {
    constructor(message) {
        this.name = 'AuthError';
        this.stack = new Error(message).stack;
        this.message = message;
    }
}

export function checkUser() {
    return axios.get('/api/signcheck').then(res => {
        if (res.status !== 204 && res.data)
            return res.data;
        else return null;
    })
}

export function signIn(email, password) {
    return axios.post('/api/signin', { email, password })
        .catch(err => {
            if (err.response && err.response.status === 401) {
                throw new AuthError("A senha que introduziu não é válida.");
            }
            else throw err;
        })
}

export function signUp(body) {
    return axios.post('/api/signup', body)
        .catch(err => {
            if (err.response.data.errors && err.response.data.errors.find(element => element.param === "email")) {
                throw new AuthError("Este email já está em uso.");
            }
            else throw err;
        })
}

export function signOut() {
    return axios.post('/api/signout');
}

export function forgotPassword(email) {
    return axios.post('/api/password/forgot', { email });
}

export function resetPassword(userId, token, body) {
    return axios.post(`/api/password/reset/${token}/${userId}`, body);
}