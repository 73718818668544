import React from "react";
const RegularHeading = ({style, children,...props}) => (
    <h2 style={Object.assign({
        marginTop: "1em", marginBottom: "0.5em",
        display: "flex",
        alignItems: "center",
        gap: "8px"
    }, style)}{...props}>
        {children}
    </h2>
)

export default RegularHeading