import {useCallback, useState} from "react";

function useBool(initialState) {
    const [state, setState] = useState(initialState);

    const enable = useCallback(() => { setState(true) }, [])
    const disable =  useCallback(() => { setState(false) }, []);

    return [ state, enable, disable];
}

export default useBool;