const UpdateStorage = (items, discountCode) => {
  localStorage.setItem("cart", JSON.stringify(items.length > 0 ? items : []));
  localStorage.setItem(
    "discount",
    JSON.stringify({
      id: discountCode.id,
      name: discountCode.name,
      isPartnerDiscount: discountCode.isPartnerDiscount,
    }) || ""
  );
};

export const calculateTotal = (items, discount = {}) => {
  if (items.length === 0) return 0;

  let withoutDiscount = items.reduce(
    (total, product) => total + product.price,
    0
  );

  if (discount.isPartnerDiscount) {
    let totalTrainingDiscount = 0;
    const trainings = items.filter((item) => item.type === "TRAINING");
    const trainingsWithoutDiscount = trainings.reduce(
      (total, product) => total + product.price,
      0
    );

    if (trainings.length > 0) {
      const trainingsWithDiscount = calculateDiscount(
        discount,
        trainingsWithoutDiscount
      );
      totalTrainingDiscount = trainingsWithoutDiscount - trainingsWithDiscount;
    }

    return parseFloat((withoutDiscount - totalTrainingDiscount).toFixed(2));
  }

  return calculateDiscount(discount, withoutDiscount);
};

export const calculateDiscount = (discount, withoutDiscount) => {
  let withDiscount = withoutDiscount;
  if (discount.type === "percentage")
    withDiscount = withoutDiscount * (1 - discount.amount / 100);
  if (discount.type === "fixed")
    withDiscount = withoutDiscount - discount.amount;
  return Math.max(0.5, withDiscount).toFixed(2);
};

export const CartReducer = (state, action) => {
  // Cart stores discount name, not the whole discount. price total may show up incorrect,
  // but server should always get it right

  let newItems = state.items;
  let newDiscount = state.discount;

  switch (action.type) {
    case "ADD_ITEM":
      if (!newItems.find((item) => item.id === action.payload.id)) {
        newItems.push(action.payload);
      }
      break;
    case "REMOVE_ITEM":
      newItems = newItems.filter((item) => item.id !== action.payload.id);
      break;
    case "CLEAR":
      newItems = [];
      newDiscount = {};
      break;
    case "APPLY_DISCOUNT":
      newDiscount = action.payload;
      break;
    case "CHANGE_AMOUNT":
      if (action.payload.amount !== "") {
        let item = newItems.findIndex((item) => item.id === action.payload.id);
        if (action.payload.amount === "0") {
          newItems = newItems.filter((item) => item.id !== action.payload.id);
          break;
        }
        //newItems[item].price = (newItems[item].price/newItems[item].amount) * action.payload.amount
        newItems[item].amount = action.payload.amount;
        newItems[item].details.amount = action.payload.amount;
      }
      break;
    case "CHECK_ITEMS":
      newItems = action.payload.items;
      newDiscount = {};
      break;
    case "REMOVE_DISCOUNT":
      newDiscount = {};
      break;
    default:
      return state;
  }

  UpdateStorage(newItems, newDiscount);

  return {
    items: newItems,
    total: calculateTotal(newItems, newDiscount),
    discount: newDiscount,
  };
};
