import React from "react";

import ButtonBase from "./ButtonBase.js";

const ButtonPrimary = ({ style, href, externalLink, ...otherProps }) => {
  const styles = Object.assign(
    {
      "--hover-color": "var(--primary-light)",
      backgroundColor: "var(--primary)",
      color: "white",

      borderRadius: "6px",
      padding: "0.25em 1.5em",
    },
    style
  );
  console.log("href", href);
  return (
    <ButtonBase
      style={styles}
      to={!externalLink ? href : undefined}
      href={externalLink ? href : undefined}
      externalLink={externalLink}
      {...otherProps}
    />
  );
};

export default ButtonPrimary;
